<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-autocomplete outlined dense :label="$t('user name')" :items="users" :loading="usersLoading" item-text="name"
          item-value="id" v-model="user_id" @keypress="fetchUsers" no-filter clearable>
        </v-autocomplete>
        <v-autocomplete outlined dense :label="$t('account')" :items="accounts" :loading="accountsLoading"
          item-text="name" item-value="id" v-model="account_id" @keypress="fetchAccounts" no-filter clearable>
        </v-autocomplete>
        <v-text-field v-model="pay_limit" :label="$t('The highest payment limit')" outlined dense color="#757575"
          type="number"></v-text-field>
        <v-text-field v-model="deposit_limit" :label="$t('The upper limit of capture')" outlined dense color="#757575"
          type="number"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="editRecord" :loading="editBtnLoading">{{ $t('edit')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
export default {
  data() {
    return {
      name: "",
      editBtnLoading: false,
      editDialog: false,
      usersLoading: false,
      users: [],
      user_id: null,
      accountsLoading: false,
      accounts: [],
      account_id: null,
      deposit_limit: null,
      pay_limit: null,
    };
  },
  props: {
    recordData: Object,
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/accounting/cashier-box/${this.recordData.id}`, {
          account_id: this.account_id,
          user_id: this.user_id,
          deposit_limit: this.deposit_limit,
          pay_limit: this.pay_limit,
        });
        this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
    async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
    async fetchAccounts(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accounts = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountsLoading = false;
        }
      }, 500)();
    },
  },
  created() {
    this.deposit_limit = this.recordData.deposit_limit;
    this.pay_limit = this.recordData.pay_limit;
    this.user_id = this.recordData.user.id;
    this.account_id = this.recordData.box.id;
    this.users.push({ ...this.recordData.user });
    this.accounts.push({ ...this.recordData.box });
  },
};
</script>

<style>

</style>