<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="2" class="mr-2" v-if="returnAbility('cashier-box:store')">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t('add cashier') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="d-flex justify-center card-title__heading">
                <h2> {{ $t('add cashier') }}</h2>
              </v-card-title>
              <v-card-text>
                <v-autocomplete outlined dense :label="$t('user name')" :items="users" :loading="usersLoading"
                  item-text="name" item-value="id" v-model="user_id" @keypress="fetchUsers" no-filter clearable>
                </v-autocomplete>
                <v-autocomplete outlined dense :label="$t('account')" :items="accounts" :loading="accountsLoading"
                  item-text="name" item-value="id" v-model="account_id" @keypress="fetchAccounts" no-filter clearable>
                </v-autocomplete>
                <v-text-field v-model="pay_limit" :label="$t('The highest payment limit')" outlined dense
                  color="#757575" type="number"></v-text-field>
                <v-text-field v-model="deposit_limit" :label="$t('The upper limit of capture')" outlined dense
                  color="#757575" type="number"></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn color="green" class="white--text" @click="submit"
                  :loading="submitBtnLoading">{{ $t('add') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-card elevation="0" class="pt-5">
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" serverRoute="/accounting/cashier-box"
        :enableDelete="displayDeleteIcon">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-cashier-box v-if="returnAbility('cashier-box:update')" :recordData="row"
            @recordUpdated="fetchData"></app-edit-cashier-box>
        </template>
      </app-base-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import editCashierBox from "./editCashierBox.vue";
export default {
  components: {
    appEditCashierBox: editCashierBox,
  },
  data() {
    return {
      dialog: false,
      submitBtnLoading: false,
      name: "",
      usersLoading: false,
      users: [],
      user_id: null,
      accountsLoading: false,
      accounts: [],
      account_id: null,
      deposit_limit: null,
      pay_limit: null,
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: 'cashierBox/getTableOptions'
    })
  },
  methods: {
    async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
    async fetchAccounts(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accounts = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountsLoading = false;
        }
      }, 500)();
    },
    async submit() {
      try {
        this.submitBtnLoading = true;
        await axios.post("/accounting/cashier-box", {
          account_id: this.account_id,
          user_id: this.user_id,
          deposit_limit: this.deposit_limit,
          pay_limit: this.pay_limit,
        });
        this.$Notifications(
          this.$t('add success'),
          { timeout: 1000, rtl: true },
          "success"
        );
        this.dialog = false;
        this.account_id = null;
        this.user_id = null;
        this.deposit_limit = null;
        this.pay_limit = null;
        this.fetchData(1);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
    ...mapActions({
      fetchCashierBox: "cashierBox/fetchCashierBox",
    }),
    fetchData(page) {
      this.fetchCashierBox({
        page,
      });
    },
  },
  created() {
    if (this.returnAbility("cashier-box:delete")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>